import React from 'react';
import { useLocation } from '@reach/router';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

import useEventListener from '@utils/use-event-listener';
import ImagePreview from '@components/ImagePreview';
import ArticleContent from '@components/ArticleContent';
import { ContentWrapper } from '@styles/layouts/ContentWrapper/ContentWrapper.styles';
import RightArrow from '@assets/img/right-arrow.svg';
import { screen } from '@styles/mixins/breakpoints';

import { css } from 'styled-components';
import ProfileTitle from './ProfileTitle';

import { StyledArrowLink, StyledProfile } from './Profile.styles';
import { getPreviousPage } from '../../utils/previousPage';

const dummyData = { socialNetworks: {}, text: { sections: {} } };

const Profile = (props) => {
  const { pathname, origin } = useLocation();
  const {
    name,
    date,
    imageUrl,
    jobTitle,
    sections,
    socialNetworks,
  } = props.pageContext ? props.pageContext.profile : dummyData;

  const { prevProfileId, nextProfileId } = props.pageContext;

  const previousPage = getPreviousPage(pathname);

  useEventListener(
    typeof window !== 'undefined' && window,
    'keydown',
    (event) => {
      const ESC_KEY_CODE = 27;
      const { keyCode } = event;

      if (keyCode === ESC_KEY_CODE) {
        navigate(previousPage);
      }
    },
  );

  const actionButtonProps = {
    to: previousPage,
  };

  const profileImageLink = `${origin}${imageUrl}`;
  const profileTitle = `${name} – ${jobTitle}`;
  const descriptionText = sections
    .find(({ type }) => type === 'text')
    .value.slice(0, 160);

  return (
    <ModalRoutingContext.Consumer>
      {() => (
        <>
          <Helmet>
            <title>{`${profileTitle} | Depop newsroom`}</title>
            <meta itemProp="image" content={profileImageLink} />
            <meta property="og:image" content={profileImageLink} />
            <meta property="twitter:image" content={profileImageLink} />
            <meta property="og:title" content={profileTitle} />
            <meta property="twitter:title" content={profileTitle} />
            <meta name="description" content={descriptionText} />
            <meta itemProp="description" content={descriptionText} />
            <meta property="og:description" content={descriptionText} />
            <meta property="twitter:description" content={descriptionText} />
          </Helmet>
          <StyledProfile>
            <ImagePreview
              sources={imageUrl.childImageSharp.gatsbyImageData}
              isProfile
              cssRules="background-size: contain"
              actionButtonProps={actionButtonProps}
            />
            <ContentWrapper>
              <StyledArrowLink
                aria-label="previous profile"
                to={`${previousPage}${prevProfileId}/`}
                customCss={css`
                  left: 100px;
                  transform: translateY(-50%) rotate(180deg);
                  ${screen.tablet(css`
                    top: 465px;
                    left: 30px;
                  `)}
                  ${screen.l(css`
                    top: 540px;
                  `)}
                `}
              >
                <RightArrow />
              </StyledArrowLink>
              <StyledArrowLink
                aria-label="next profile"
                to={`${previousPage}${nextProfileId}/`}
                customCss={css`
                  right: 100px;
                  transform: translateY(-50%);
                  ${screen.tablet(css`
                    top: 465px;
                    right: 30px;
                  `)}
                  ${screen.l(css`
                    top: 540px;
                  `)}
                `}
              >
                <RightArrow />
              </StyledArrowLink>
              <ProfileTitle
                date={date}
                name={name}
                jobTitle={jobTitle}
                socialNetworks={socialNetworks}
              />
              <ArticleContent content={sections} />
            </ContentWrapper>
          </StyledProfile>
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default Profile;
