import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { fonts } from '@styles/variables';

const StyledProfileTitle = styled.h1`
  padding: 40px 0 40px;
  font: 20px/30px ${fonts.fontPrimaryBold};
  text-align: center;
`;

const StyledProfileTitleName = styled.p`
  margin-bottom: 11px;
  font: 32px/48px ${fonts.fontSecondaryBold}, Arial, sans-serif;
  letter-spacing: 0.12px;
  text-align: center;

  ${screen.l(css`
    font-size: 24px;
    line-height: 30px;
  `)}
`;

const StyledProfileTitlePosition = styled.div`
  margin-bottom: 16px;
  font: 14px/21px ${fonts.fontPrimaryRegular};
  letter-spacing: 0;
  text-align: center;
`;

const StyledProfileTitleSocials = styled.ul`
  display: flex;
  justify-content: center;
`;

const StyledProfileTitleSocialsItem = styled.li`
  margin: 0 13px;

  a {
    display: block;
  }

  svg:hover {
    fill: $color-dove-grey;
  }
`;

export default {
  StyledProfileTitleSocialsItem,
  StyledProfileTitleSocials,
  StyledProfileTitle,
  StyledProfileTitleName,
  StyledProfileTitlePosition,
};
