import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import styles from './ArticleContent.styles';

const {
  StyledArticleHeading,
  StyledArticleText,
  StyledArticleQuote,
  StyledArticleImage,
  StyledArticleButton,
  StyledArticleContentSection,
  StyledArticleContent,
  StyledLinkToDownload,
} = styles;

const ArticleContent = ({ content: contentItems = [], cssRules = '' }) => {
  const renderText = ({ id, htmlId, value }) => (
    <StyledArticleText
      key={id}
      id={htmlId}
      cssRules={cssRules}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );

  const renderHeading = ({ id, value, cssRules = '' }) => (
    <StyledArticleHeading
      key={id}
      cssRules={cssRules}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );

  const renderQuote = ({ id, value }) => (
    <StyledArticleQuote key={id} dangerouslySetInnerHTML={{ __html: value }} />
  );

  const renderImage = useCallback(
    ({ id, src, altText = '' }) => (
      <StyledArticleImage key={id}>
        <GatsbyImage
          image={src?.childImageSharp?.gatsbyImageData}
          backgroundColor="#EEEEEE"
          alt={altText}
          loading="lazy" />
      </StyledArticleImage>
    ),
    [],
  );

  const renderButton = useCallback(
    ({ id, value, downloadUrl }) => (
      <StyledArticleButton key={id}>
        <StyledLinkToDownload
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </StyledLinkToDownload>
      </StyledArticleButton>
    ),
    [],
  );

  const detectRenderedItem = {
    text: renderText,
    heading: renderHeading,
    quotedText: renderQuote,
    image: renderImage,
    button: renderButton,
  };

  const selectAltTextForImage = (nodeIndex, content) => {
    const resultText = content.find(
      ({ type }, index) => type !== 'image' && index > nodeIndex,
    ).value;

    return resultText.replace(/<\/?[^>]+>/gi, ' ').slice(0, 124) || 'article preview';
  };

  const isContentExists = contentItems && contentItems.length > 0;

  const renderContent = useCallback(
    ({ content, enableSpacing }) => isContentExists && (
    <StyledArticleContentSection>
      {content
        .filter(
          ({ type, value, sections }) => !!type || !!value || !!sections,
        )
            .map(({ id, htmlId, type, value, src, downloadUrl, sections }, index) => {
          if (type) {
            const altText = type === 'image' && selectAltTextForImage(index, content);
            return detectRenderedItem[type]({
              id,
                  htmlId,
              value,
              src,
              altText,
              downloadUrl,
              enableSpacing,
            });
          }

          return renderContent({
            id,
            content: sections,
            enableSpacing: false,
          });
        })}
    </StyledArticleContentSection>
    ),
    [detectRenderedItem, isContentExists, selectAltTextForImage],
  );

  const content = renderContent({
    content: contentItems,
    enableSpacing: true,
  });

  return <StyledArticleContent>{content}</StyledArticleContent>;
};

ArticleContent.defaultProps = {
  content: [],
  cssRules: '',
};

ArticleContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.any),
  cssRules: PropTypes.string,
};

export default ArticleContent;
