import styled, { css } from 'styled-components';
import { colors } from '@styles/variables';
import { Link } from 'gatsby';

export const StyledProfile = styled.div`
  padding-top: 50px;
`;

export const StyledArrowLink = styled(Link)`
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;
  outline: none;
  ${({ disabled }) => !disabled
    && css`
      cursor: pointer;

      &:hover svg path {
        fill: ${colors.colorDoveGrey};
        stroke: ${colors.colorDoveGrey};
      }
    `}
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
  
  svg {
    width: 50px;
    height: 44px;
  }

  ${({ customCss }) => customCss};
`;
