import React, { useState } from 'react';

import InstagramIcon from '@icons/instagram.svg';
import TwitterIcon from '@icons/twitter.svg';
import LinkedinIcon from '@icons/linkedin.svg';

import styles from './ProfileTitle.styles';

const {
  StyledProfileTitleSocialsItem,
  StyledProfileTitleSocials,
  StyledProfileTitle,
  StyledProfileTitleName,
  StyledProfileTitlePosition,
} = styles;

const ProfileTitle = (props) => {
  const { name, jobTitle, socialNetworks } = props;
  const [socialIcons] = useState({
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    linkedin: LinkedinIcon,
  });

  const renderSocial = ({ type, value: href }) => {
    const SocialIcon = socialIcons[type];
    return (
      <StyledProfileTitleSocialsItem key={type}>
        <a href={href} className="" target="_blank" rel="noopener noreferrer">
          <SocialIcon />
        </a>
      </StyledProfileTitleSocialsItem>
    );
  };

  const renderSocials = () => (socialNetworks.length ? (
    <StyledProfileTitleSocials>
      {socialNetworks.map(renderSocial)}
    </StyledProfileTitleSocials>
  ) : null);

  const socials = renderSocials();

  return (
    <>
      <StyledProfileTitle>
        <StyledProfileTitleName>{name}</StyledProfileTitleName>
        <StyledProfileTitlePosition>{jobTitle}</StyledProfileTitlePosition>
        {socials}
      </StyledProfileTitle>
    </>
  );
};

export default ProfileTitle;
